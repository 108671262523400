import React from 'react';
import '../../Assets/sass/_Dashboard.scss'
import {AccountConsumer} from "../../Providers/Account";

const SlackButton = () => (
    <AccountConsumer>
        {
            ({oAuth}) =>
                <a href={oAuth}>
                    <img alt="Sign in with Slack" width="115" height="30"
                         src="https://platform.slack-edge.com/img/sign_in_with_slack.png"
                         srcSet="https://platform.slack-edge.com/img/sign_in_with_slack.png 1x, https://platform.slack-edge.com/img/sign_in_with_slack@2x.png 2x"/>
                </a>
        }
    </AccountConsumer>
);

export const UserLogin = () => (
    <AccountConsumer>
        {
            ({logo, loggedOut}) =>
                <div className="dashboard p-3">
                    <div className="row justify-content-center">
                        {loggedOut && <SlackButton/>}
                    </div>
                </div>
        }
    </AccountConsumer>
);
