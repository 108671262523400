// Vendor Scripts
import React, {Component} from 'react'
import Cookies from 'js-cookie'
import {Navbar} from './Components/Navbar/Navbar'
import {Dashboard} from './Components/Dashboard/Dashboard'
import {UserLogin} from './Components/Dashboard/UserLogin'
// Template Dependencies
import logo from "./Assets/img/funko-dojo_logo.png"
import LoadingOverlay from 'react-loading-overlay'
import 'bootstrap/dist/js/bootstrap.bundle'
import 'daemonite-material'
import './Assets/sass/App.scss'
import {Waves} from "./Components/Waves/Waves"
import {Quotes} from "./Components/random_quotes"
// Data Providers
import {AccountProvider} from "./Providers/Account"

export default class App extends Component {
    // TEST DATA FOR LOCAL DEVELOPMENT
    /*state = {
        productCost: 20,
        isLoading: false,
        loggedOut: false,
        snackbar: {
            messageType: null,
            isOpen: false,
            message: '',
        },
        billingUser: {
            addressOne: null,
            addressTwo: null,
            city: null,
            country: null,
            firstName: null,
            lastName: null,
            state: null,
            zipCode: null,
        },
        slackUser: {
          profileImage:null,
        },
        showPaymentForm: true,
        pushUpdate: this.setState.bind(this),
        handleSubmit: this.handleFormSubmit.bind(this),
        handleUserStatus: this.handleUserStatus.bind(this),
        openSnackbar: this.openSnackbar.bind(this),
        closeSnackbar: this.closeSnackbar.bind(this)
    };*/

     state = {
         productCost: 20,
         isLoading: true,
         loggedOut: true,
         snackbar: {
             messageType: null,
             isOpen: false,
             message: '',
         },
         showPaymentForm: true,
         pushUpdate: this.setState.bind(this),
         handleSubmit: this.handleFormSubmit.bind(this),
         handleUserStatus: this.handleUserStatus.bind(this),
         openSnackbar: this.openSnackbar.bind(this),
         closeSnackbar: this.closeSnackbar.bind(this)
     };

    componentWillMount() {
        const api_key = 'pk_live_dMAXxCarDWE5OxmUmDxk3G6q00dZ4uXiie',
            client_id = '335878685026.1207479496487',
            URI = 'https://login.funkodojo.com/',
            scope = ['identity.basic', 'identity.email', 'identity.team', 'identity.avatar'],
            _slack = {scope, client_id, URI},
            oAuth = `https://slack.com/oauth/v2/authorize?scope=&user_scope=${_slack.scope}&client_id=${_slack.client_id}&redirect_uri=${_slack.URI}`;

        this.setState({
            logo,
            oAuth,
            stripe: window.Stripe(api_key),
            code: this.parseURL(window.location)
        });
    }

    componentDidMount() {
        const {code} = this.state;
        //console.log(
        //    code ? `User Code Loaded!` : `User is logged out...`
        //);
        code ? this.fetchUser(code, "authCode") : this.fetchUser(Cookies.get('sessionId'), "sessionId");

        const { history, location } = window;
        history.pushState(null, "", location.href.split("?")[0]);
    }

    openSnackbar(messageType, message) {
        //console.log('open snackbar');
        this.setState({
            snackbar: {
                messageType,
                message,
                isOpen: true,
            }
        });
        setTimeout(() => this.closeSnackbar(), 2000 )
    };

    closeSnackbar() {
        //console.log('close snackbar');
        this.setState({
            snackbar: {
                isOpen: false,
            }
        });
    };

    parseURL(search) {
        //console.log("Loading User Code...");
        const url = new URL(search);
        return url.searchParams.get("code");
    }

    fetchUser(id, idType) {
        let requestData = {};
        requestData[idType] = id;
        this.setState({
            isLoading: true
        });

        const toggleLoadingScreen = () => {
            this.setState({
                isLoading: false
            })
        };

        //console.log(`Loading User Data...`);

        fetch("https://login.funkodojo.com/user", {
            method: 'POST',
            body: JSON.stringify(requestData),
        })
            .then(response => response.json())
            .then(data => {
                //console.log(data.data);
                const {
                    paymentActive,
                    billingActive,
                    expirationDate,
                    sessionId,
                    initDate,
                    paymentUser,
                    billingUser,
                    slackUser,
                } = data.data;


                // If user has an active membership or if the user has
                // a stored card on file then payment form will be hidden.
                if (paymentActive || paymentUser && paymentUser.lastFour !== null) {
                    this.setState({
                        showPaymentForm: false
                    })
                }

                if (slackUser || paymentUser) {
                    const d1 = new Date(); //"now"
                    const d2 = new Date(expirationDate); //"next payment date"
                    const dtr = Math.floor(Math.abs(d2 - d1) / 1000 / 60 / 60 / 24);
                    const ptr = String((dtr * 100) / 30);
                    if (idType === "authCode") {
                        Cookies.set('sessionId', sessionId)
                    }
                    this.setState({
                        loggedOut: false,
                        paymentActive,
                        billingActive,
                        sessionId,
                        initDate,
                        slackUser,
                        paymentUser,
                        billingUser,
                        dtr: dtr,
                        ptr: ptr
                    })
                }
            }).then(() => {
                setTimeout(function () {
                    toggleLoadingScreen()
                }, 3000)
            }
        )
    }

    handleFormSubmit(event, formType) {
        event.preventDefault();
        const {billingUser, paymentUser, sessionId} = this.state;
        let url;
        let data;
        if (formType === "payment") {
            url = `edit${formType}`;
            data = {
                'stripeId': paymentUser.token.id,
                'cardType': paymentUser.token.card.brand,
                'lastFour': paymentUser.token.card.last4,
                sessionId
            }
        } else if (formType === "billing") {
            url = `edit${formType}`;
            data = {
                'firstName': billingUser.firstName,
                'lastName': billingUser.lastName,
                'addressOne': billingUser.addressOne,
                'addressTwo': billingUser.addressTwo,
                'city': billingUser.city,
                'state': billingUser.state,
                'zipCode': billingUser.zipCode,
                sessionId
            }
        } else if (formType === "coupon") {
            url = `add${formType}`;
            data = {
                "couponCode": event.target.promoCode.value,
                sessionId
            }
        }

        //console.log(`url is ${url}`);
        //console.log(data);

        fetch(`https://login.funkodojo.com/${url}`, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        })
            .then(response => response.json())
            .then(data => {
                data.success
                    ? this.openSnackbar('success', `Successfully updated ${formType} information!`)
                    : this.openSnackbar('danger', `Error: ${data.data.error}.`)
            })
    }

    handleUserStatus(event) {
        event.preventDefault();
        let url = this.state.paymentActive ? '/cancelsubscription' : '/activatesubscription';

        const requestData = {
            sessionId: this.state.sessionId
        };

        //console.log(requestData);

        fetch('https://login.funkodojo.com' + url, {
            method: 'POST',
            body: JSON.stringify(requestData),
        })
            .then(response => response.json())
            .then(data => {
								data.paymentActive
										? this.openSnackbar('success', `Subscription Successfully Initiated!`)
										: this.openSnackbar('danger', `Subscription Has Been Cancelled.`);
                this.state.pushUpdate({
                    paymentActive: !this.state.paymentActive
                })
            })
    }

    render() {

        const {loggedOut, isLoading, snackbar, closeSnackbar} = this.state;
        const {messageType, message, isOpen} = snackbar;

        return (
            <AccountProvider value={this.state}>

                <LoadingOverlay
                    active={isLoading}
                    spinner
                    styles={{
                        wrapper: {
                            width: '100%',
                            position: 'absolute',
                            left: 0,
                            height: '100%',
                            top: 0,
                            overflowX: 'hidden',
                            opacity: 1,
                        },
                        overlay: {
                            position: 'absolute',
                            height: '100%',
                            width: '100%',
                            top: '0px',
                            left: '0px',
                            display: 'flex',
                            textAlign: 'center',
                            justifyContent: 'center',
                            alignItems: 'center',
                            fontSize: '1.2em',
                            color: '#FFF',
                            background: '#7c98eb',
                            zIndex: 800,
                        },
                        content: {
                            margin: 0
                        }
                    }}
                    className={`${loggedOut && "d-flex justify-content-center align-items-center vh-100"}`}
                    text={Quotes[Math.floor(Math.random() * Quotes.length)]}
                >

                    <div className={`content-container shadow-24`}>

                        <Navbar/>
                        {loggedOut ? <UserLogin/> : <Dashboard/>}

                        <div className={`snackbar bg-${messageType} ${isOpen && 'show'}`} onClick={closeSnackbar}>
                            <div className="snackbar-body">
                                <span>{message}</span>
                            </div>
                        </div>

                    </div>

                </LoadingOverlay>
                <Waves/>
            </AccountProvider>
        );
    };
}

// TODO: Check billing information form for class "is-invalid" if any element has this class and it is visible then return null rather than method 'pushUpdate'
// TODO: Activate membership button should not 'pushUpdate' if disabled. This will probably need to be added to onSubmit rather than onClickst
