import React from 'react';
import {AccountConsumer} from "../../Providers/Account";

const BillingInformationForm = () => (
    <AccountConsumer>
        {({billingUser, pushUpdate, handleSubmit }) =>
            <div data-form="billing">
                <form className="needs-validation"
                      onSubmit={(event) => {
                          event.preventDefault();
                    
                          pushUpdate({
                                  billingActive: true,
                                  billingUser: {
                                      addressOne: event.target.inputAddress.value,
                                      addressTwo: event.target.inputAddressTwo.value,
                                      city: event.target.inputCity.value,
                                      country: event.target.inputCountry.value,
                                      firstName: event.target.firstName.value,
                                      lastName: event.target.lastName.value,
                                      state: event.target.inputState.value,
                                      zipCode: event.target.inputZip.value
                                  },
                              },
                              function () {
                                  handleSubmit(event, 'billing')
                              });
                      }}
                      noValidate>
                    <fieldset>
                        <legend>If Country isn't an option please message an Admin it added.</legend>
                        <div className="form-container">
                            
                            {/* FIRST/LAST NAME */}
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label htmlFor="firstName">First Name</label>
                                    <input type="text"
                                           className="form-control"
                                           id="firstName"
                                           placeholder="First Name"
                                           defaultValue={billingUser.firstName ? billingUser.firstName : null}
                                           required
                                    />
                                    <div className="invalid-feedback">
                                        Please enter your first name.
                                    </div>
                                </div>
                                <div className="form-group col-md-6">
                                    <label htmlFor="lastName">Last Name</label>
                                    <input type="text"
                                           className="form-control"
                                           id="lastName"
                                           defaultValue={billingUser.lastName ? billingUser.lastName : null}
                                           placeholder="Last Name"
                                           required
                                    />
                                    <div className="invalid-feedback">
                                        Please enter your last name.
                                    </div>
                                </div>
                            </div>
                            
                            {/* ADDRESS */}
                            <div className="form-group">
                                <label htmlFor="inputAddress">Address</label>
                                <input type="text"
                                       className="form-control"
                                       id="inputAddress"
                                       defaultValue={billingUser.addressOne ? billingUser.addressOne : null}
                                       placeholder="Address"
                                       required
                                />
                                <div className="invalid-feedback">
                                    Please enter a valid address.
                                </div>
                            </div>
    
                            {/* ADDRESS TWO */}
                            <div className="form-group">
                                <label htmlFor="inputAddressTwo">Address 2</label>
                                <input type="text"
                                       className="form-control"
                                       id="inputAddressTwo"
                                       placeholder="Apartment, studio, or floor"
                                       defaultValue={billingUser.addressTwo ? billingUser.addressTwo : null}
                                />
                            </div>
                            
                            {/* COUNTRY */}
                            {/* onChange setState to selected country and display the proper 'state/territory' dropdown */}
                            <div className="form-row">
                                <div className="form-group col-md-12">
                                    <label htmlFor="inputCountry">Country</label>
                                    <select className="form-control"
                                        id="inputCountry"
                                        defaultValue={billingUser.country ? billingUser.country : null}
                                        style={{marginTop: "4px"}}
                                        onChange={(event) => {
                                            pushUpdate({
                                                billingUser: {
                                                    country: event.target.value
                                                }
                                            });
                                        }} required>
                                        <option value="" defaultValue hidden>Select a Country</option>
                                        <option value="Australia">Australia</option>
                                        <option value="Canada">Canada</option>
                                        <option value="United Kingdom">United Kingdom</option>
                                        <option value="United States">United States</option>
                                    </select>
                                </div>
                            </div>
    
                            {billingUser.country &&
                            <div className="form-row">
        
                                {/* CITY / TOWN */}
                                <div className="form-group col-12">
                                    <label htmlFor="inputCity">City or town</label>
                                    <input type="text"
                                           className="form-control"
                                           id="inputCity"
                                        // placeholder="City"
                                           defaultValue={billingUser.city ? billingUser.city : null}
                                           required
                                    />
                                    <div className="invalid-feedback">Please enter a valid city or town.</div>
                                </div>
    
                                {/* AU: TERRITORY */}
                                {/* TODO: Hide US UK CA */}
                                { billingUser.country === "Australia" &&
                                    <div className="form-group col">
                                    <label htmlFor="inputState">Territory</label>
                                    <select id="inputState"
                                    defaultValue={billingUser.state}
                                    className="form-control"
                                    style={{marginTop: "4px"}}
                                    required>
                                    <option value="" defaultValue hidden>Choose Territory</option>
                                    <option value="act">Australian Capital Territory</option>
                                    <option value="nsw">New South Wales</option>
                                    <option value="nt">Northern Territory</option>
                                    <option value="qld">Queensland</option>
                                    <option value="sa">South Australia</option>
                                    <option value="tas">Tasmania</option>
                                    <option value="vic">Victoria</option>
                                    <option value="wa">Western Australia</option>
                                    </select>
                                    <div className="invalid-feedback">Please select a territory.</div>
                                    </div>
                                }
                                {/* CA: PROVINCE */}
                                {/* TODO: Hide US UK AU */}
                                { billingUser.country === "Canada" &&
                                    <div className="form-group col">
                                    <label htmlFor="inputState">Province</label>
                                    <select id="inputState"
                                    defaultValue={billingUser.state}
                                    className="form-control"
                                    style={{marginTop: "4px"}}
                                    required>
                                    <option value="" defaultValue hidden>Choose Province</option>
                                    <option value="AB">Alberta</option>
                                    <option value="BC">British Columbia</option>
                                    <option value="MB">Manitoba</option>
                                    <option value="NB">New Brunswick</option>
                                    <option value="NL">Newfoundland and Labrador</option>
                                    <option value="NS">Nova Scotia</option>
                                    <option value="ON">Ontario</option>
                                    <option value="PE">Prince Edward Island</option>
                                    <option value="QC">Quebec</option>
                                    <option value="SK">Saskatchewan</option>
                                    <option value="NT">Northwest Territories</option>
                                    <option value="NU">Nunavut</option>
                                    <option value="YT">Yukon</option>
                                    </select>
                                    <div className="invalid-feedback">Please select a province.</div>
                                    </div>
                                }
                                {/* UK: COUNTY */}
                                {/* TODO: Hide US CA AU */}
                                {billingUser.country === "United Kingdom" &&
                                    <div className="form-group col">
                                    <label htmlFor="inputState">County</label>
                                    <select className="form-control"
                                    id="inputState"
                                    defaultValue={billingUser.city ? billingUser.city : null}
                                    style={{marginTop: "4px"}}
                                    required
                                    >
                                    <option value="" defaultValue hidden>Choose County</option>
                                    <optgroup label="England">
                                    <option>Bedfordshire</option>
                                    <option>Berkshire</option>
                                    <option>Bristol</option>
                                    <option>Buckinghamshire</option>
                                    <option>Cambridgeshire</option>
                                    <option>Cheshire</option>
                                    <option>City of London</option>
                                    <option>Cornwall</option>
                                    <option>Cumbria</option>
                                    <option>Derbyshire</option>
                                    <option>Devon</option>
                                    <option>Dorset</option>
                                    <option>Durham</option>
                                    <option>East Riding of Yorkshire</option>
                                    <option>East Sussex</option>
                                    <option>Essex</option>
                                    <option>Gloucestershire</option>
                                    <option>Greater London</option>
                                    <option>Greater Manchester</option>
                                    <option>Hampshire</option>
                                    <option>Herefordshire</option>
                                    <option>Hertfordshire</option>
                                    <option>Isle of Wight</option>
                                    <option>Kent</option>
                                    <option>Lancashire</option>
                                    <option>Leicestershire</option>
                                    <option>Lincolnshire</option>
                                    <option>Merseyside</option>
                                    <option>Norfolk</option>
                                    <option>North Yorkshire</option>
                                    <option>Northamptonshire</option>
                                    <option>Northumberland</option>
                                    <option>Nottinghamshire</option>
                                    <option>Oxfordshire</option>
                                    <option>Rutland</option>
                                    <option>Shropshire</option>
                                    <option>Somerset</option>
                                    <option>South Yorkshire</option>
                                    <option>Staffordshire</option>
                                    <option>Suffolk</option>
                                    <option>Surrey</option>
                                    <option>Tyne and Wear</option>
                                    <option>Warwickshire</option>
                                    <option>West Midlands</option>
                                    <option>West Sussex</option>
                                    <option>West Yorkshire</option>
                                    <option>Wiltshire</option>
                                    <option>Worcestershire</option>
                                    </optgroup>
                                    <optgroup label="Wales">
                                    <option>Anglesey</option>
                                    <option>Brecknockshire</option>
                                    <option>Caernarfonshire</option>
                                    <option>Carmarthenshire</option>
                                    <option>Cardiganshire</option>
                                    <option>Denbighshire</option>
                                    <option>Flintshire</option>
                                    <option>Glamorgan</option>
                                    <option>Merioneth</option>
                                    <option>Monmouthshire</option>
                                    <option>Montgomeryshire</option>
                                    <option>Pembrokeshire</option>
                                    <option>Radnorshire</option>
                                    </optgroup>
                                    <optgroup label="Scotland">
                                    <option>Aberdeenshire</option>
                                    <option>Angus</option>
                                    <option>Argyllshire</option>
                                    <option>Ayrshire</option>
                                    <option>Banffshire</option>
                                    <option>Berwickshire</option>
                                    <option>Buteshire</option>
                                    <option>Cromartyshire</option>
                                    <option>Caithness</option>
                                    <option>Clackmannanshire</option>
                                    <option>Dumfriesshire</option>
                                    <option>Dunbartonshire</option>
                                    <option>East Lothian</option>
                                    <option>Fife</option>
                                    <option>Inverness-shire</option>
                                    <option>Kincardineshire</option>
                                    <option>Kinross</option>
                                    <option>Kirkcudbrightshire</option>
                                    <option>Lanarkshire</option>
                                    <option>Midlothian</option>
                                    <option>Morayshire</option>
                                    <option>Nairnshire</option>
                                    <option>Orkney</option>
                                    <option>Peeblesshire</option>
                                    <option>Perthshire</option>
                                    <option>Renfrewshire</option>
                                    <option>Ross-shire</option>
                                    <option>Roxburghshire</option>
                                    <option>Selkirkshire</option>
                                    <option>Shetland</option>
                                    <option>Stirlingshire</option>
                                    <option>Sutherland</option>
                                    <option>West Lothian</option>
                                    <option>Wigtownshire</option>
                                    </optgroup>
                                    <optgroup label="Northern Ireland">
                                    <option>Antrim</option>
                                    <option>Armagh</option>
                                    <option>Down</option>
                                    <option>Fermanagh</option>
                                    <option>Londonderry</option>
                                    <option>Tyrone</option>
                                    </optgroup>
                                    </select>
                                    <div className="invalid-feedback">Please select a county.</div>
                                    </div>
                                }
                                {/* US: STATE */}
                                {/* TODO: Hide UK CA AU */}
                                { billingUser.country === "United States" &&
                                    <div className="form-group col">
                                    <label htmlFor="inputState">State</label>
                                    <select id="inputState"
                                    defaultValue={billingUser.state}
                                    className="form-control"
                                    style={{marginTop: "4px"}}
                                    required>
                                    <option value="" defaultValue hidden>Choose State</option>
                                    <option value="AL">AL</option>
                                    <option value="AK">AK</option>
                                    <option value="AR">AR</option>
                                    <option value="AZ">AZ</option>
                                    <option value="CA">CA</option>
                                    <option value="CO">CO</option>
                                    <option value="CT">CT</option>
                                    <option value="DC">DC</option>
                                    <option value="DE">DE</option>
                                    <option value="FL">FL</option>
                                    <option value="GA">GA</option>
                                    <option value="HI">HI</option>
                                    <option value="IA">IA</option>
                                    <option value="ID">ID</option>
                                    <option value="IL">IL</option>
                                    <option value="IN">IN</option>
                                    <option value="KS">KS</option>
                                    <option value="KY">KY</option>
                                    <option value="LA">LA</option>
                                    <option value="MA">MA</option>
                                    <option value="MD">MD</option>
                                    <option value="ME">ME</option>
                                    <option value="MI">MI</option>
                                    <option value="MN">MN</option>
                                    <option value="MO">MO</option>
                                    <option value="MS">MS</option>
                                    <option value="MT">MT</option>
                                    <option value="NC">NC</option>
                                    <option value="NE">NE</option>
                                    <option value="NH">NH</option>
                                    <option value="NJ">NJ</option>
                                    <option value="NM">NM</option>
                                    <option value="NV">NV</option>
                                    <option value="NY">NY</option>
                                    <option value="ND">ND</option>
                                    <option value="OH">OH</option>
                                    <option value="OK">OK</option>
                                    <option value="OR">OR</option>
                                    <option value="PA">PA</option>
                                    <option value="RI">RI</option>
                                    <option value="SC">SC</option>
                                    <option value="SD">SD</option>
                                    <option value="TN">TN</option>
                                    <option value="TX">TX</option>
                                    <option value="UT">UT</option>
                                    <option value="VT">VT</option>
                                    <option value="VA">VA</option>
                                    <option value="WA">WA</option>
                                    <option value="WI">WI</option>
                                    <option value="WV">WV</option>
                                    <option value="WY">WY</option>
                                    </select>
                                    <div className="invalid-feedback">Please select a state.</div>
                                    </div>
                                }
        
                                {/* UNIVERSAL: ZIP CODE / POSTAL CODE */}
                                <div className="form-group col">
                                    <label htmlFor="inputZip">Postal Code</label>
                                    <input type="text"
                                           className="form-control"
                                           id="inputZip"
                                        // placeholder="Zip Code"
                                           defaultValue={billingUser.zipCode ? billingUser.zipCode : null}
                                        // pattern="\d*"
                                           maxLength="5"
                                           required/>
                                </div>
    
                            </div>
                            }
                            <button type="submit" className="btn btn-info">Save</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        }
    </AccountConsumer>
);

export const BillingInformation = () => (
    <div aria-labelledby="billing-tab" className="tab-pane fade show active"
    id="billing-info" role="tabpanel">
        <BillingInformationForm/>
    {/*<p className="font-weight-bold mt-3">Disclaimer: ...</p>*/}
    </div>
);
