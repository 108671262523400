import React from 'react'

export const Waves = () => (

  <div className="area">
    <ul className="circles">
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
      <li />
    </ul>
  </div>

  //
  // <div className="container-fluid">
  //     <div className="ocean">
  //         <div className="wave"/>
  //         <div className="wave"/>
  //     </div>
  // </div>
);
