import React from 'react'
import {Icon} from "../Icon/Icon";

export const SidebarNav = () => (
    <div className="col-lg-4 pr-lg-0 d-none d-lg-flex align-items-start">
        <div className="card">
            <ul className="nav nav-tabs" role="tablist">
                {/*<li>*/}
                    {/*<a aria-selected="true" className="nav-link" data-toggle="tab" href="#billing-info"*/}
                       {/*id="billing-tab" role="tab">*/}
                        {/*<Icon type="home"/>*/}
                        {/*<span>News & Updates</span>*/}
                    {/*</a>*/}
                {/*</li>*/}
                <li>
                    <a aria-selected="true" className="nav-link active" data-toggle="tab" href="#billing-info"
                       id="billing-tab" role="tab">
                        <Icon type="contact_mail"/>
                        <span>Billing Information</span>
                    </a>
                </li>
                <li>
                    <a aria-selected="false" className="nav-link" data-toggle="tab" href="#payment-info"
                       id="payment-tab" role="tab">
                        <Icon type="credit_card"/>
                        <span>Payment Settings</span>
                    </a>
                </li>
                <li>
                    <a aria-selected="false" className="nav-link" data-toggle="tab" href="#promo-code"
                       id="promo-tab" role="tab">
                        <Icon type="local_offer"/>
                        <span>Enter Promo Code</span>
                    </a>
                </li>
            </ul>
        </div>
    </div>
);
