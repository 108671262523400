import React from 'react';
import {Content} from './Content'
import {SidebarNav} from "../Navbar/SidebarNav";
import {UserProfile} from "./UserProfile";
import '../../Assets/sass/_Dashboard.scss'
import {ContentTabs} from "../Navbar/ContentTabs";

export const Dashboard = props => (
    <div className="dashboard p-3">
        <div className="row">
            {/* User account details panel   */} <UserProfile/>
            {/* Desktop dashboard navigation */} <SidebarNav/>
            {/* Mobile dashboard navigation  */} <ContentTabs/>
            {/* Dashboard content container  */} <Content handleBillingSubmit={props.handleBillingSubmit} handlePaymentSubmit={props.handlePaymentSubmit}/>
        </div>
    </div>
);
