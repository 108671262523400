import React from 'react'
import {AccountConsumer} from "../../Providers/Account";

// const joinedOn = date => {
//     const d = new Date(date * 1000);
//     const month = d.getMonth() + 1;
//     const day = d.getDate();
//     let year = d.getFullYear();
//     return `${month}/${day}/${year}`
// };

export const UserProfile = () => (
    <AccountConsumer>
        { context =>
            <div className="user-profile">
                <div className="card p-3">

                    <div className="row justify-content-center">
                        <div className="col flex-column flex-grow-0 align-items-center">
                            <img src={context.slackUser.profileImage} className="avatar" alt="" />
                        </div>
                        <div className="pl-lg-0 col flex-grow-1">
                            <h5 className="card-title mb-0 font-weight-bold">{context.slackUser.displayNameNormalized}</h5>
                            <p className="m-0">
                                <span className="font-weight-bold">
                                    Subscription:
                                </span>
                                <span className={`${context.paymentActive ? "text-success" : "text-danger"}`}>
                                    {context.paymentActive ? " Active" : " Inactive"}
                                </span>
                            </p>
                            <p className="m-0">
                                <span className="font-weight-bold">
                                    Price:
                                </span>${context.productCost}/month
                            </p>
                            {/*<p className="m-0"><span*/}
                                {/*className="font-weight-bold">Member since:</span> {joinedOn(context.initDate).toLocaleString()}*/}
                            {/*</p>*/}
                            <p className="m-0"><span
                                className="font-weight-bold">{context.paymentActive ? 'Renews in ' : 'Deactivates in '}{context.dtr} days</span>
                            </p>
                            <div className="progress">
                                <div className="progress-bar bg-info pb-1"
                                     role="progressbar"
                                     style={
                                         {
                                             width: `${ context.ptr }%`
                                         }
                                     }
                                     aria-valuenow="25"
                                     aria-valuemin="0"
                                     aria-valuemax="100"
                                />
                            </div>

                            {/* If billingUser & paymentUser are filled then display button */}
                            {/* If paymentActive is True then show Cancel Button with btn-danger background */}
                            {/*{ context.billingUser && context.paymentUser &&*/}
                                {/*<button onClick={context.handleUserStatus}*/}
                                    {/*className={`btn btn-sm btn-register*/}
                                    {/*${ context.paymentActive ? 'btn-danger' : 'btn-light'} `} >*/}
                                    {/*{ context.paymentActive ? "Cancel Subscription" : "Activate Subscription" }*/}
                                {/*</button>*/}
                            {/*}*/}

                        </div>
                    </div>
                </div>
            </div>
        }
    </AccountConsumer>
);
