import React from 'react'
import {BillingInformation} from "../Forms/BillingInformation";
import {PaymentSettings} from "../Forms/PaymentSettings";
import {PromoCode} from "../Forms/PromoCode";

export const Content = props => (
    <div className="col-lg-8">
        <div className="card tab-content w-100 p-3" id="dashboard">
            <BillingInformation/>
            <PaymentSettings/>
            <PromoCode/>
        </div>
    </div>
);
