import React from 'react'

export const ContentTabs = () => (
    <div className="d-lg-none px-1 mb-3" style={{margin: "0 auto"}}>
        <ul className="nav nav-tabs flex-row flex-nowrap text-center" id="myTab" role="tablist">
            <li className="nav-item">
                <a aria-controls="billing-info" aria-selected="true" className="nav-link active" data-toggle="tab"
                   href="#billing-info"
                   id="billing-tab" role="tab">Billing Info</a>
            </li>
            <li className="nav-item">
                <a aria-controls="payment-info" aria-selected="false" className="nav-link" data-toggle="tab"
                   href="#payment-info"
                   id="payment-tab" role="tab">Payment Method</a>
            </li>
            <li className="nav-item">
                <a aria-controls="promo-code" aria-selected="false" className="nav-link" data-toggle="tab"
                   href="#promo-code"
                   id="promo-tab" role="tab">Promo Code</a>
            </li>
        </ul>
    </div>
);
