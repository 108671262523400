import React from 'react';
// import {
//     Elements,
//     CardElement,
//     StripeProvider
// } from "react-stripe-elements";
import {AccountConsumer} from "../../Providers/Account";

const PromoCodeForm = () => (
    <AccountConsumer>
        { context =>
            <div data-form="billing">
                <form onSubmit={(event) => {
                  context.handleSubmit(event, 'coupon')
                }
              }>
                    <fieldset>
                        <legend>Claim Promo Code</legend>
                        <div className="form-container">

                            {/* PROMO CODE */}
                            <div className="form-group">
                                <label htmlFor="promoCode">Enter Promo Code</label>
                                <input type="text" className="form-control" id="promoCode" placeholder="FD-1234-1245"/>
                            </div>

                            <button type="submit" className="btn btn-info">Submit</button>
                        </div>
                    </fieldset>
                </form>
            </div>
        }
    </AccountConsumer>
);

export const PromoCode = () => (
  <div aria-labelledby="promo-code" className="tab-pane fade" id="promo-code" role="tabpanel">
      <PromoCodeForm />
      {/*<p className="font-weight-bold mt-3">Disclaimer: The information stored within this form will be used for renewing your monthly subscription as well as any.... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid at enim fugit obcaecati sapiente tenetur velit voluptatum! Labore natus perferendis provident quia? Cum dolores doloribus eos molestias praesentium quod voluptates?</p>*/}
  </div>
);
