import React from 'react';
import {CardElement, Elements, injectStripe, StripeProvider} from "react-stripe-elements";
import {AccountConsumer} from "../../Providers/Account";
import {Icon} from "../Icon/Icon";

const PaymentMethodForm = props => (
  <AccountConsumer>
    {({paymentUser, pushUpdate, handleSubmit, productCost, showPaymentForm, handleUserStatus, paymentActive, dtr, billingUser, billingActive}) =>
      <div data-form="billing">
        <form onSubmit={(event) => {
          event.preventDefault();
          props.stripe.createToken()
            .then(({token}) => {
              if (token)
                pushUpdate({
                    showPaymentForm: false,
                    paymentUser: {
                      ...paymentUser,
                      token
                    }
                  },
                  function () {
                    handleSubmit(event, 'payment')
                  }
                );
              });
        }}>
          <fieldset>
            <div className="form-container">

            { showPaymentForm
              ?
              <React.Fragment>
                <CardElement/>
                <button type="submit" className="btn btn-info">Save</button>
              </React.Fragment>
              :
              <div className="row justify-content-center align-items-center">
                <div className="col flex-grow-0">
                  { billingActive
                    // Billing form is filled & Payment is saved
                    ? paymentActive
                      ? <Icon type="done"/>
                      : <Icon type = "notification_important" />
                    // Billing form is not filled.
                    : <Icon type = "room" />
                  }
                </div>
                <div className="col">

                  <p className="text-center text-lg-left text-bold h3">
                    { billingActive
                      // Billing form is filled & Payment is saved
                      ? paymentActive
                        ? "Payment Stored"
                        : "Activate Subscription"
                      // Billing form is not filled.
                      : 'Billing Info Missing'
                    }
                  </p>
                  <p>
                    { billingActive
                      // Billing form is filled & Payment is saved
                      ? paymentActive
                        ? `Your ${paymentUser.cardType} ending in ${paymentUser.lastFour} will be charged a recurring fee
                          of $${productCost}/USD in ${dtr} days unless you deactivate membership. If you deactivate your
                          membership you will no longer have access to the content of this dashboard or the Slack server.
                          You will need to contact an admin to regain access.`
                        : `Almost there! By clicking the "Activate Subscription" button below you agree to be billed a
                          recurring fee of $20/USD for 30 days of service. You may cancel at anytime by deactivating your
                          subscription. If you deactivate your
                          membership you will no longer have access to the content of this dashboard or the Slack server.
                          You will need to contact an admin to regain access.`
                        : 'Oops! You must update your billing information before you can activate your account.'
                    }
                  </p>

                  {/* TODO: Button should not pushUpdate if disabled. */}
                  <button onClick={handleUserStatus}
                    className={`btn btn-sm btn-register mb-3
                      ${ paymentActive ? 'btn-danger' : 'btn-success' }
                      ${ !billingActive && 'disabled' }
                    `} >
                    { paymentActive ? "Cancel Subscription" : "Activate Subscription" }
                  </button>
                  <button type="button" className="btn btn-light" onClick={
                    () => pushUpdate({
                      showPaymentForm: true
                    })
                  } >Update Payment Method</button>
                </div>
              </div>
            }

            </div>
          </fieldset>
        </form>
      </div>
    }
  </AccountConsumer>
);

const StripePaymentMethod = injectStripe(PaymentMethodForm);

export const PaymentSettings = props => (
  <div aria-labelledby="payment-info" className="tab-pane fade" id="payment-info" role="tabpanel">
    <AccountConsumer>
      {({user, stripe}) =>
        <StripeProvider stripe={stripe}>
          <Elements>
            <StripePaymentMethod/>
          </Elements>
        </StripeProvider>
      }
    </AccountConsumer>

    {/*<p className="font-weight-bold mt-3">Disclaimer: The information stored within this form will be used for renewing your monthly subscription as well as any.... Lorem ipsum dolor sit amet, consectetur adipisicing elit. Aliquid at enim fugit obcaecati sapiente tenetur velit voluptatum! Labore natus perferendis provident quia? Cum dolores doloribus eos molestias praesentium quod voluptates?</p>*/}
  </div>
);
