import React from "react";
import {AccountConsumer} from "../../Providers/Account";
import {Icon} from '../Icon/Icon'

const LogoutButton = () => (
    <AccountConsumer>
        { ({ pushUpdate }) =>
          <div className="d-flex align-items-center" onClick={
              () => pushUpdate({
                  loggedOut:true
              })
          }>
              <Icon className="d-flex" type="exit_to_app" />
          </div>
        }
    </AccountConsumer>
);

export const Navbar = () => (
    <AccountConsumer>
        {
            ({ logo, loggedOut }) =>
                <nav
                    className={`navbar navbar-light bg-white ${loggedOut ? "justify-content-center" : "justify-content-between"}`}>
                <a className="navbar-brand ml-0" href="/">
                    <img src={logo} alt="Funko Dojo"/>
                    <span className="ml-2">funko dojo.</span>
                </a>

                    {!loggedOut && <LogoutButton/>}
            </nav>
        }
    </AccountConsumer>
);
